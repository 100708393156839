<template>
    <div class="downloadProgressDialog">
        <el-dialog :title="myTitle" width="400px"  :visible.sync="dialogVisible" :close-on-click-modal="false">
          <div style="text-align: center;">
            <el-progress :percentage="percentage" type="circle"></el-progress>
          </div>
          <div style="text-align: center;" v-if="totalSize > 0">
            {{downSize | unitM}}/{{totalSize | unitM}}M
          </div>
            <div slot="footer" class="dialog-footer">
              <el-button type="primary" :loading="loading" :disabled="loading" @click="download">下载</el-button>
              <el-button @click="cancel">隐藏</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'
    import axios from "axios";

    export default {
        name: 'downloadProgressDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: '正在下载，请等待', percentage: 0,
                dialogVisible:false, loading: false, totalSize: 0,  downSize: 0,
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
	    },
      filters:{
        unitM: function (v){
          if (typeof v === 'number') {
            return ( v/1024/1024).toFixed(2)
          }
        }
      },
        props: {
            downUrl: {
                type: String
            },
          downFileName: {
            type: String
          }
        },
        watch: {

        },
        methods: {

          download() {
            let that = this
            this.loading = true
            this.myTitle = '正在下载，请等待';
            //进度条恢复为 0
            this.percentage= 0;
            //发起请求
            axios.defaults.headers = {'Content-Type': 'application/json; application/octet-stream'}
            axios({
              url: this.downUrl,
              method:"get",
              responseType:"blob",
              params:{},
              //xml返回数据的钩子函数，可以用来获取数据的进度 具体可查看axios的官网
              onDownloadProgress:(progressEvent)=>{
                //progressEvent.loaded 下载文件的当前大小
                //progressEvent.total  下载文件的总大小 如果后端没有返回 请让他加上！
                this.downSize = progressEvent.loaded
                this.totalSize = progressEvent.total
                let progressBar = Math.round( progressEvent.loaded / progressEvent.total*100);
                //接收进度为99%的时候需要留一点前端编译的时间
                if(progressBar >= 99){
                  this.percentage = 99;
                  // this.$message.success("下载完成，文件正在编译。");
                  // this.title = '下载完成，文件正在编译。';
                }else{
                  this.percentage = progressBar;
                  // this.$message("正在下载，请稍等。");
                  // this.title = '正在下载，请稍等。';
                }
                that.loading = true
                // console.log(progressEvent)
              }
            }).then(res => {
              // console.log(res)
              if(res.status != 200){
                this.$message.error("文件下载失败"+res.status+"，请稍后重试！");
                this.loading = false
                return false;
              }
              //blob返回的是一个 base64 格式的文件流
              let blob = new Blob([res.data],{
                //编译文件为xlsx类型。 具体可查看 blob 方法定义的类型
                type:"application/zip"
              })
              //编译文件
              if(window.navigator && window.navigator.meSaveOrOpenBlob){
                window.navigator.meSaveOrOpenBlob(blob,that.downFileName)
              }else{
                // 非ie的下载
                const link = document.createElement('a');
                const body = document.querySelector('body');
                link.href = window.URL.createObjectURL(blob);
                link.download = that.downFileName; // 下载的文件名
                // fix Firefox
                link.style.display = 'none';
                body.appendChild(link);
                link.click();
                body.removeChild(link);
                window.URL.revokeObjectURL(link.href);
              }
              //编译文件完成后，进度条展示为100%100
              this.percentage =100;
              this.dialogVisible = false; // 关闭进度条显示层
              this.loading = false
            }).catch(err=>{
              this.myTitle = '文件下载失败';
              that.loading = false
            })
          },

            showDialog(){
              this.loading = false
              this.myTitle = '请点击下载';
              this.percentage= 0;
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            handleClose(index){
              let d = this.selectList[index]
              this.tichuChange({id: d.id, is_tichu: false })
              this.selectList.splice(index, 1);
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .downloadProgressDialog{

    }


</style>


