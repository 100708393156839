<template>
    <div class="downloadList" >
        <div class="search_container searchArea">
            <el-form :inline="true" :model='searchForm' ref="searchForm" class="demo-form-inline search-form">

                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" placeholder="试卷名称"  @keyup.enter.native='handleSearch'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size ="mini" icon="search" @click='handleSearch'>查询</el-button>
                </el-form-item>

            </el-form>
        </div>
        <el-table v-loading="loading" :data="tableData" align='center' :height="myHeight-80" @row-click="rowClick">
          <el-table-column prop="exam_id" label="key" max-width="80"  align='center' ></el-table-column>
          <el-table-column prop="exam_name" label="考试名称" align='center' ></el-table-column>
          <el-table-column prop="exam_time" label="考试日期" align='center' ></el-table-column>
          <el-table-column prop="score_report" min-width="120" label="剔除学生" align='center' >
            <template slot-scope='scope'>
              <el-button type="info" :disabled="scope.row.status!='ZC'" size="mini" @click='openIgnoreStudent(scope.row)'>设置</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="score_count" label="成绩单" align='center' >
            <template slot-scope='scope'>
              <el-button type="primary" :disabled="scope.row.status!='ZC'" icon='edit' size="mini" @click='reCalcScore(scope.row, "SC")'>重新计算</el-button>
              <el-button type="text" :disabled="scope.row.status!='ZC'" icon='edit' size="mini" @click='downloadScore(scope.row)'>下载</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="score_report" min-width="120" label="成绩报告" align='center' >
            <template slot-scope='scope'>
              <el-button type="primary" :disabled="scope.row.status!='ZC'" size="mini" @click='openScoreReport(scope.row)'>生成</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="re_score" min-width="120" label="成绩重算" align='center' >
            <template slot-scope='scope'>
              <el-button type="danger" icon='edit' :disabled="scope.row.status!='ZC'" size="mini" @click='reCalcScore(scope.row, "CJ")'>核算成绩(慢)</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="re_mark" min-width="120" label="第一步" align='center' >
            <template slot-scope='scope'>
              <el-button type="warning" icon='edit' size="mini" @click='reMarkExam(scope.row)'>绘制答题卡</el-button>
            </template>
          </el-table-column>
          <el-table-column prop="re_score" min-width="120" label="第二步" align='center' >
            <template slot-scope='scope'>
              <el-button type="primary" icon='edit' :disabled="scope.row.packaging_status!='01'" size="mini" @click='downloadStudentFile(scope.row)'>下载答题卡</el-button>
            </template>
          </el-table-column>

        </el-table>
        <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>


        <scoreReportDialog ref="scoreReportDialog" :info="selDownload" ></scoreReportDialog>
        <examTopicDialog ref="examTopicDialog"></examTopicDialog>
      <ignoreStudentDialog ref="ignoreStudentDialog" :ignoreStudent="selDownload2" @ok='reCalcScore(scope.row, "SC")'></ignoreStudentDialog>
      <download-progress-dialog ref="downloadProgressDialog" :down-url="downFileUrl" :down-file-name="downFileName" />
    </div>
</template>

<script>
    import * as mUtils from '@/utils/mUtils'
    import {mapState, mapActions} from 'vuex'
    import XEUtils from 'xe-utils'
    import { saveAs } from 'file-saver';
    import * as simpleApi from "request/simple";
    import Pagination from "@/components/pagination";
    import scoreReportDialog from "./scoreReportDialog";
    import examTopicDialog from  "./examTopicDialog";
    import ignoreStudentDialog from "./ignoreStudentDialog";
    import downloadProgressDialog from "./downloadProgressDialog";
    import XLSX from "xlsx";
    import axios from "axios";

    export default {
        name: 'examsList',
        data(){
            return {
                tableData: [], tableHeight:0, downLoading: false, downFileUrl: null, downFileName: null,
                loading:false, isShow:false, selDownload: null, selDownload2: null, myHeight: 300, myWidth: 200,
                examList: [], selExam: null, archiveList: [],
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, examId: null},
                examForm: {archiveName: null},
                columnHeaders: { '基本信息': ['name', 'sex', 'age'], '成绩': ['class', 'teacher', 'score']},
                uploadUrl: null,
                uploadHeader: {
                    'Company-url': 'http://www.szpcyl.com',
                    'X-Powered-By': 'raozq',
                    'ke-Token': 'noLogin',
                    'ke-Domain': 'WB',
                },
            }
        },
        components:{
            scoreReportDialog, examTopicDialog, ignoreStudentDialog, downloadProgressDialog, Pagination
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
                userToken: state => state.user.token,
            })
        },
        timers: {
          autoFresh: {time: 30000, autostart: false, repeat: true},
        },
      	mounted() {

            let that = this;
            this.$nextTick(()=>{
                let myHeight = document.documentElement.clientHeight -150;
                let myWidth = document.documentElement.clientWidth -250;
                console.log('myHeight', myHeight);
                that.myHeight = myHeight;
                that.myWidth = myWidth;
            })
            let myHost = process.env.NODE_ENV=='production'?window.g.PROD_HOST:window.g.DEV_HOST;
            this.uploadUrl = myHost + 'exam/archiveAtta/upload?';
            this.uploadHeader["ke-Token"] = this.userToken;
            this.loadDownloadList();
	   },
        methods: {
            handleSearch(){
              this.searchForm.pageNo = 1;
              this.loadDownloadList();
              if(!this.timers.autoFresh.isRunning) {
                this.$timer.start('autoFresh');
              }
            },
            autoFresh(){
              this.loadDownloadList();
            },
            loadDownloadList(){
                this.loading = true;
                this.selExam = null;
                this.archiveList = [];
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                    schoolId: null, teacherId: null, score: 'sum', orderBy: 'e.id desc'};
                let role = this.userInfo.role;
                if(role == "sclAdmin") search["schoolId"] = this.userInfo.schoolId;
                if(role == "teacher") {
                    search["schoolId"] = this.userInfo.schoolId;
                    search["teacherId"] = this.userInfo.id;
                }
                let param = { controllerName: 'exam', methodName: '/downloadExamList', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        let list = data.result;
                        this.tableData = list;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },
            rowClick(row, column, e){
                this.selExam = row;
                this.loadArchive(row.exam_id)
            },
            loadArchive(examId){
                this.archiveList = [];
                let param = { controllerName: 'exam/archive', methodName: '/list', param: {examId: examId}};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log("reloadArchive", data)
                    if (code == 0 && data && data.length > 0) {
                        this.archiveList = data;
                    }
                }).catch((error) => {
                    console.log("error", error)
                });
            },
            handleChange(file, fileList) {
                console.log(file, fileList);
                if(file && "success" == file.status){
                    this.$message({ type: 'success', message: '上传成功!'});
                    this.loadArchive(file.response.file.examId);
                }
            },
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadDownloadList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadDownloadList()
            },
            reMarkExam(item){
              let that = this;
              this.$confirm('重绘答题卡需要几分钟，请耐心等待！，是否确认重绘?', '提示', {
                confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
              }).then(() => {

                let param = { controllerName: 'score/ctrl', methodName: '/updateStatus', param: { examId: item.exam_id, packagingStatus: '00' }};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                  if (code == 0) {
                    that.$message({ type: 'success', message: '答题卡开始绘制，请等待!'});
                    that.loadDownloadList();
                  }
                });
              }).catch(() => {

              });
            },
            downloadStudentFile(item) {
              // this.$alert('准备下载答题卡需要几分钟，请耐心等待！', '提示', {
              //   confirmButtonText: '确定'
              // });
              // this.loading = true;
              // // saveAs(window.g.OSS_HOST+"answer/exam/"+item.licence_id+"/"+item.exam_id+"/all.zip?_=" + (new Date()).getTime(), item.exam_name+"_学生成绩.zip");
              // saveAs(window.g.OSS_HOST+"exam-mark/"+item.exam_id+"-mark.zip?_=" + (new Date()).getTime(), item.exam_name+"_学生成绩.zip");
              // this.loading = false;

              let url = window.g.OSS_HOST+"exam-mark/"+item.exam_id+"-mark.zip?_=" + (new Date()).getTime();
              let fileName = item.exam_name+"_学生成绩.zip"
              // saveAs(url, fileName);
              this.downFileName = fileName
              this.downFileUrl = url;
              this.$refs.downloadProgressDialog.showDialog();
            },
            downloadScore(item){
                saveAs(window.g.OSS_HOST+"answer/analyse/"+item.exam_id+"/student.xlsx?_=" + (new Date()).getTime(), item.exam_name+"_学生成绩.xlsx");
            },
            downloadArchiveFile(item) {
                this.loading = true;
                saveAs(window.g.OSS_HOST+item.oss_name+"?_=" + (new Date()).getTime(), item.file_name);
                this.loading = false;
            },
            addArchiveName(){
                let that = this;
                this.$refs.examForm.validate((valid) => {
                    if (valid) {
                        let param = { controllerName: 'exam/archive', methodName: '/saveOrUpdate', param: { name: that.examForm.archiveName, examId: that.selExam.exam_id, teacherId: this.userInfo.id }};
                        simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                            if (code == 0) {
                                that.$message({ type: 'success', message: '添加成功!'});
                                that.loadArchive(that.selExam.exam_id);
                            }
                        });
                    }
                });

            },
            delArchive(row){
                let that = this;
                this.$confirm('是否删除'+row.file_name+'?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'exam/archive', methodName: '/delete', param: { id: row.id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            that.loadArchive(that.selExam.exam_id);
                        }
                    });
                }).catch(() => {

                });
            },
            delAtta(row){
                let that = this;
                this.$confirm('是否删除此材料?', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'exam/archiveAtta', methodName: '/delete', param: { id: row.id }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '删除成功!'});
                            that.loadArchive(that.selExam.exam_id);
                        }
                    });
                }).catch(() => {

                });
            },
            reCalcScore(item, status){
                let that = this;
                let msg = '此操作会使整张试卷所有学生成绩重新计算一遍，耗时较长，是否确认重算?';
                if(status == 'SC') msg = '确认重新生成报表？';
                this.$confirm(msg, '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'
                }).then(() => {

                    let param = { controllerName: 'score/ctrl', methodName: '/updateStatus', param: { examId: item.exam_id, status: status }};
                    simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                        if (code == 0) {
                            that.$message({ type: 'success', message: '重算成功!'});
                            that.loadDownloadList();
                        }
                    });
                }).catch(() => {

                });
            },

            outputXlsxFile(data, wscols, xlsxName) {
                console.log(data, wscols, xlsxName)
                let sheetNames = [];
                let sheetsList = {};
                let wb = XLSX.utils.book_new();

                for (let key in data) {
                    sheetNames.push(key);
                    let columnHeader = this.columnHeaders[key] // 此处是每个sheet的表头
                    let temp = this.transferData(data[key], columnHeader);
                    sheetsList[key] = XLSX.utils.aoa_to_sheet(temp);
                    sheetsList[key]["!cols"] = wscols;
                }
                console.log("sheetNames", sheetNames)
                wb["SheetNames"] = sheetNames;
                wb["Sheets"] = sheetsList;

                XLSX.writeFile(wb, xlsxName + ".xlsx");
            },
            transferData(data, columnHeader) {
                let content = [];
                content.push(columnHeader);
                data.forEach((item, index) => {
                    let arr = [];
                    columnHeader.map(column =>{
                        arr.push(item[column]);
                    })
                    content.push(arr);
                });
                return content;
            },
            loadExamCtrl(item){
                this.loading = true;
                let that = this;
                let h = window.location.protocol;
                axios.defaults.headers = {'Content-Type': 'application/json;charset=UTF-8'}
                axios({
                    method:'get',
                    url: window.g.OSS_HOST+"answer/analyse/"+item.exam_id+"/exam_student_"+item.exam_id+".json",
                }).then((res)=>{
                    let data = res.data;
                    console.log(data);
                    that.examCount.joinCount = data.join_count;
                    that.examCount.totalCount = data.student_count;
                    that.examCount.hardClassCount = data.hard_count;
                    that.examInfo.totalScore = data.full_score;
                    that.examCount.maxScore = data.score_max;
                    that.examCount.avaScore = data.score_avg;
                    that.examInfo.hard = data.hard_mode;
                    that.examInfo.diff = data.discrimination;
                    let score_level = data.score_level;
                    let good_count = 0, up_count = 0;
                    for (let i=0; i<score_level.length; i++){
                        if(score_level[i].name == "优秀") good_count = score_level[i].count;
                        if(score_level[i].name != "不及格") up_count = up_count + score_level[i].count;
                    }
                    that.examCount.goodScale = Math.round(good_count/data.join_count *10000 )/100 ;
                    that.examCount.upScale = Math.round(up_count/data.join_count*10000)/100;
                    that.setScoreBarData(data.score_count);
                    that.setScoreLevelData(data.score_level);
                    that.setClassBarData(data.hard_avg_count);
                    that.tiData = data.scores;
                    that.jsonData = data;
                    that.loading = false;
                }).catch((error) => {
                    that.loading = false;
                });

            },
            openScoreReport(row){
                this.selDownload = row;
                this.$refs.scoreReportDialog.showDialog();
            },
            openIgnoreStudent(row){
              this.selDownload2 = row;
              this.$refs.ignoreStudentDialog.showDialog();
            },
            showExamTopicDialog(row){
                this.$refs.examTopicDialog.loadExamTopicList(row.exam_id);
                this.$refs.examTopicDialog.showDialog();
            },

        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .downloadList{
        overflow-y: hidden;
        .detail {
            padding: 10px;
            border: 1px solid rgba(203, 202, 202, 0.71);
            border-radius: 15px;
        }
        .search_container{

        }
        .btnRight{
            float: right;
            margin-right: 0px !important;
        }
        .searchArea{
            background:rgba(255,255,255,1);
            border-radius:2px;
            padding: 18px 18px 0;
        }
        .table_container{
            padding: 10px;
            background: #fff;
            border-radius: 2px;
        }
        .el-dialog--small{
           width: 600px !important;
        }
        .pagination{
            text-align: left;
            margin-top: 10px;
        }
    }

</style>


