<template>
    <div class="examTopicDialog">
        <el-dialog :title="myTitle" width="60%"  :visible.sync="dialogVisible" :close-on-click-modal="false">


                <el-table v-loading="loading" :data="tableData" style="width: 100%;" height="500" align='center'>
                    <el-table-column label="序号" prop="topic_index" align='center'></el-table-column>
                    <el-table-column prop="topic_no" label="题号"  align='center' ></el-table-column>
                    <el-table-column prop="topic_type2" label="题型" :formatter="formatterType" align='center' ></el-table-column>
                    <el-table-column prop="topic_type" label="题型2" :formatter="formatterType" align='center' ></el-table-column>
                    <el-table-column prop="score" label="满分"  align='center' ></el-table-column>
                    <el-table-column prop="answer" label="答案" align='center' ></el-table-column>
                    <el-table-column prop="missing_score" label="漏选得分" align='center' ></el-table-column>
                    <el-table-column prop="answer_b" label="题目B答案" align='center' ></el-table-column>
                    <el-table-column prop="score_rules" label="特殊得分规则" :formatter="formatterScoreRule" align='center' ></el-table-column>
                </el-table>

            
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">关  闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'examTopicDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: '参考答案',
                tableData: [], tableHeight:0,
                isShow:false,
                searchForm:{ pageNo: 1, pageSize: 50, totalCount: 0, searchText: null, examId: null, },
                dialogVisible:false, loading: false, examId: 0,


            }
        },
        components:{

        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {

        },
        watch: {

        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            formatterType(row, column, cellValue, index){
                if(cellValue == "Object")return "客观题";
                if(cellValue == "Subjective")return "主观题";
                if(cellValue == "SingleChoice")return "单选题";
                if(cellValue == "MultiChoice")return "多选题";
                if(cellValue == "Judge")return "判断题";
                if(cellValue == "AnswerQuestion")return "解答题";
                if(cellValue == "FillBlank")return "填空题";
                if(cellValue == "Composition")return "作文题";
                if(cellValue == "EnglishComposition")return "英语作文题";

                return cellValue;
            },
            formatterScoreRule(row, column, cellValue, index) {
                if(cellValue == null || cellValue == "") return "";
                try{
                    let jo = JSON.parse(cellValue);
                    let s = "";
                    for (let i=0; i<jo.length; i++){
                        let d = jo[i];
                        if("answer" in d && "score" in d){
                            s = s + "选择："+d.answer + "，得"+d.score + "分,";
                        }
                    }
                    return s;
                }catch (e) {
                    console.log("formatterScoreRule", e);
                }

                return "";
            },
            search(){
                this.loadExamTopicList(this.searchForm.examId);
            },
            loadExamTopicList(examId){
                this.loading = true;
                this.searchForm.examId = examId;
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                    examId: examId};
                let param = { controllerName: 'examScore', methodName: '/examTopic', pageNo: 1, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        let list = data.result;
                        this.tableData = list;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },
            submitForm(){
                this.$emit('ok');
            },
            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadStudentList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadStudentList()
            },
        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .examTopicDialog{
        .my-row{
            padding: 10px;
        }

        .head-warning {
            margin-top: 20px;
            color: red;
        }

    }


</style>


