<template>
    <div class="ignoreStudentDialog">
        <el-dialog :title="myTitle" width="60%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-form :inline="true" :model='searchForm' ref="searchForm" @submit.native.prevent  class="demo-form-inline search-form">
                <el-form-item label="查找">
                    <el-input v-model="searchForm.searchText" size="mini" placeholder="姓名或学号"  @keyup.enter.native='loadList'></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="info" size ="mini" icon="search" @click='loadList'>查询</el-button>
                </el-form-item>
            </el-form>

            <el-table v-loading="loading" :data="tableData" height="350" style="width: 100%" align='center'>
                <el-table-column prop="hard_name" label="班级" align='center' ></el-table-column>
                <el-table-column prop="student_no" label="学号" align='center' ></el-table-column>
                <el-table-column prop="student_name" label="姓名" align='center' ></el-table-column>
                <el-table-column prop="status" label="状态" :formatter="formatterStatus" align='center' ></el-table-column>
                <el-table-column prop="operation" align='center' label="操作">
                    <template slot-scope='{row}'>
                      <el-switch v-model="row.is_tichu" active-text="剔除" inactive-text="正常" @change="tichuChange(row)"> </el-switch>
                    </template>
                </el-table-column>
            </el-table>
            <pagination  :pageSize="searchForm.pageSize" :currentPage="searchForm.pageNo" :pageTotal="searchForm.totalCount" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange"></pagination>
            <div style="margin-top: 20px; text-align: left;">
              <el-tag v-for="(tag, index) in selectList" :key="tag.id" closable  @close="handleClose(index)"> {{tag.name}}</el-tag>
            </div>
            <div slot="footer" class="dialog-footer">
              <el-button @click="cancel">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'ignoreStudentDialog',
        data(){
            return {
                formLabelWidth: '120px', myTitle: null,
                dialogVisible:false, loading: false, examId: null,  rules: null,
                tableData: [], selectList: [], teacherList: [],
                searchForm: { pageNo: 1, pageSize: 50, totalCount: 0, teacherId: null, searchText: null, licenceId: null, examId: null, },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            })
        },
      	mounted() {
	    },
        props: {
            ignoreStudent: {
                type: Object
            }
        },
        watch: {
            ignoreStudent: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                      this.examId = n.id;
                      this.myTitle = n.exam_name + "(" +n.exam_id+")剔除学生";
                        this.searchForm.searchText = null;
                      this.searchForm.examId = n.exam_id;
                      this.searchForm.licenceId = n.licence_id;
                      this.loadList()
                      this.loadSelectList();
                    }else{
                      this.tableData = []
                    }

                }
            },
        },
        methods: {
            handleSelectionChange(val){
              console.log("handleSelectionChange", val);
              this.multipList = val;
            },
            loadList(){
                this.loading = true;
                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.searchForm.licenceId, examId: this.searchForm.examId,};
                let param = { controllerName: 'scan/scanPreResult', methodName: '/list', pageNo: this.searchForm.pageNo, pageSize: this.searchForm.pageSize, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                      let myList = data.result;
                      if(myList != null && myList.length > 0){
                        for (let i=0; i<myList.length; i++){
                          let d = myList[i];
                          d["is_tichu"] = (d.status == 'TC')?true:false;
                        }
                      }
                      this.tableData = myList;
                        this.searchForm.pageNo = data.pageNo;
                        this.searchForm.pageSize = data.pageSize;
                        this.searchForm.totalCount = data.totalCount;
                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },
            loadSelectList(){
              let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.searchForm.licenceId, examId: this.searchForm.examId, status: "TC"};
              let param = { controllerName: 'scan/scanPreResult', methodName: '/list', pageNo: 1, pageSize: 500, param: search};
              this.selectList = []
              return simpleApi.list(param).then(({result: {code, data}}) =>  {
                console.log(data)
                if (data.code == 0) {
                  let tableData = data.result;
                  if(tableData && tableData.length > 0){
                    for (let i=0; i<tableData.length; i++){
                      let d = tableData[i]
                      this.selectList.push({id: d.id, name: d.student_name});
                    }
                  }
                }
                this.loading = false;
              }).catch((error) => {
                this.loading = false;
                console.log("error")
              });
            },
            formatterStatus(row, column, cellValue, index) {
              if(cellValue == 'TC') return '剔除';
              return null;

            },
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            handleClose(index){
              let d = this.selectList[index]
              this.tichuChange({id: d.id, is_tichu: false })
              this.selectList.splice(index, 1);
            },
            tichuChange(row){
              console.log("thirdPartChange", row);
              let that = this;
              if("is_tichu" in row){
                this.loading = true;
                let param = { controllerName: 'scan/scanPreResult', methodName: '/updateStatus', param: {id: row.id, status: row.is_tichu?"TC":"00"}};
                simpleApi.post(param).then(({ result: { code, data}, sysCode}) =>  {
                  if (code == 0) {
                    that.$message({message: '更新成功', type: 'success'});
                    that.loadList()
                    that.loadSelectList();
                  }
                  this.loading = false;
                });
              }
            },
            // 上下分页
            handleCurrentChange(val){
                this.searchForm.pageNo = val;
                this.loadTeachingClassList()
            },
            // 每页显示多少条
            handleSizeChange(val){
                this.searchForm.pageSize = val;
                this.loadTeachingClassList()
            },


        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .ignoreStudentDialog{

    }


</style>


